<template>
  <div class="repayment-page">
    <div v-if="$system.user.isAuthenticated" class="container">
      <!--Page title-->
      <Title :title="title" />

      <!--Repayment-->
      <div v-if="!loading">
        <div class="row">
          <div class="col-12">
            <b-tabs content-class="mt-3" fill>
              <!--Repayment Detail-->
              <b-tab title="Detail" active>
                <div class="row">
                  <!--Repayment Name-->
                  <div class="col-6 col-lg-4">
                    <div class="repayment__item">
                      <div class="repayment__item__title">Name:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.Name">
                          {{ repayment.Name }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                  </div>
                  <!--Repayment Type-->
                  <div class="col-6 col-lg-4">
                    <div class="repayment__item">
                      <div class="repayment__item__title">Type:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.Type">
                          {{ repayment.Type }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                  </div>
                  <!--Repayment Status-->
                  <div class="col-6 col-lg-4">
                    <div class="repayment__item">
                      <div class="repayment__item__title">Status:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.Status">
                          {{ repayment.Status }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                  </div>
                  <!--Repayment Start Date-->
                  <div class="col-6 col-lg-4">
                    <div class="repayment__item">
                      <div class="repayment__item__title">Start Date:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.StartDate">
                          {{ repayment.StartDate }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                  </div>
                  <!--Repayment Factor Rate-->
                  <div class="col-6 col-lg-4">
                    <div class="repayment__item">
                      <div class="repayment__item__title">Factor Rate:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.FactorRate">
                          {{ repayment.FactorRate }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                  </div>
                  <!---Repayment CVE-->
                  <div class="col-6 col-lg-4">
                    <div class="repayment__item">
                      <div class="repayment__item__title">CVE:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.CVE">
                          {{ repayment.CVE }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                  </div>
                  <!---Repayment Account-->
                  <div class="col-6 col-lg-4">
                    <div class="repayment__item">
                      <div class="repayment__item__title">Account:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.Account">
                          <router-link
                            :to="'/accounts/' + repayment.Account.Id"
                            v-b-tooltip.hover
                            title="Account information"
                          >
                            {{ repayment.Account.Name }}
                          </router-link>
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                  </div>
                </div>
                <!---Balances-->
                <div
                  v-if="repayment.OnFull || repayment.OnDisbursed"
                  class="row"
                >
                  <div class="col-12 m-0">
                    <div class="accordion" @click="showBalance()">
                      <b-icon-arrow-right-circle-fill
                        v-if="!balance"
                        class="accordion__icon"
                      />
                      <b-icon-arrow-down-circle-fill
                        v-else
                        class="accordion__icon"
                      />
                      <h3 class="accordion__title">Balances</h3>
                    </div>
                  </div>
                </div>
                <div v-if="balance" class="row">
                  <!--On Full-->
                  <div v-if="repayment.OnFull" class="col-6 col-lg-6">
                    <div class="mb-2">
                      <b>On Full:</b>
                    </div>
                    <div class="repayment__item">
                      <div class="repayment__item__title">Advance Amount:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.OnFull.AdvanceAmount">
                          {{ repayment.OnFull.AdvanceAmount }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                    <div class="repayment__item">
                      <div class="repayment__item__title">Payback Amount:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.OnFull.PaybackAmount">
                          {{ repayment.OnFull.PaybackAmount }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                    <div class="repayment__item">
                      <div class="repayment__item__title">Pending Balance:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.OnFull.PendingBalance">
                          {{ repayment.OnFull.PendingBalance }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                    <div class="repayment__item">
                      <div class="repayment__item__title">
                        Total Payment Amount:
                      </div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.OnFull.TotalPaymentAmount">
                          {{ repayment.OnFull.TotalPaymentAmount }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                    <div class="repayment__item">
                      <div class="repayment__item__title">Cleared Paid:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.OnFull.ClearedPaid">
                          {{ repayment.OnFull.ClearedPaid }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                    <div class="repayment__item">
                      <div class="repayment__item__title">Cleared Balance:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.OnFull.ClearedBalance">
                          {{ repayment.OnFull.ClearedBalance }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                    <div class="repayment__item">
                      <div class="repayment__item__title">Percent Paid:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.OnFull.PercentPaid">
                          <b-progress
                            :value="repayment.OnFull.PercentPaid"
                            variant="success"
                            max="100%"
                            :precision="2"
                            show-value
                            show-progress
                            class="mb-1"
                          ></b-progress>
                          {{ repayment.OnFull.PercentPaid }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                  </div>
                  <!--On Disbursed-->
                  <div v-if="repayment.OnDisbursed" class="col-6 col-lg-6">
                    <div class="mb-2">
                      <b>On Disbursed:</b>
                    </div>
                    <div class="repayment__item">
                      <div class="repayment__item__title">Advance Amount:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.OnDisbursed.AdvanceAmount">
                          {{ repayment.OnDisbursed.AdvanceAmount }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                    <div class="repayment__item">
                      <div class="repayment__item__title">Payback Amount:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.OnDisbursed.PaybackAmount">
                          {{ repayment.OnDisbursed.PaybackAmount }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                    <div class="repayment__item">
                      <div class="repayment__item__title">Pending Balance:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.OnDisbursed.PendingBalance">
                          {{ repayment.OnDisbursed.PendingBalance }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                    <div class="repayment__item">
                      <div class="repayment__item__title">
                        Total Payment Amount:
                      </div>
                      <div class="repayment__item__text">
                        <template
                          v-if="repayment.OnDisbursed.TotalPaymentAmount"
                        >
                          {{ repayment.OnDisbursed.TotalPaymentAmount }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                    <div class="repayment__item">
                      <div class="repayment__item__title">Cleared Paid:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.OnDisbursed.ClearedPaid">
                          {{ repayment.OnDisbursed.ClearedPaid }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                    <div class="repayment__item">
                      <div class="repayment__item__title">Cleared Balance:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.OnDisbursed.ClearedBalance">
                          {{ repayment.OnDisbursed.ClearedBalance }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                    <div class="repayment__item">
                      <div class="repayment__item__title">Percent Paid:</div>
                      <div class="repayment__item__text">
                        <template v-if="repayment.OnDisbursed.PercentPaid">
                          <b-progress
                            :value="repayment.OnDisbursed.PercentPaid"
                            variant="success"
                            max="100%"
                            :precision="2"
                            show-value
                            show-progress
                            class="mb-1"
                          ></b-progress>
                          {{ repayment.OnDisbursed.PercentPaid }}
                        </template>
                        <template v-else>—</template>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Recurring Payments-->
                <div v-if="repayment.RecurringPayments" class="row">
                  <div class="col-12 m-0">
                    <div class="accordion" @click="showRecurringPayments()">
                      <b-icon-arrow-right-circle-fill
                        v-if="!recurringPayments"
                        class="accordion__icon"
                      />
                      <b-icon-arrow-down-circle-fill
                        v-else
                        class="accordion__icon"
                      />
                      <h3 class="accordion__title">Recurring Payments</h3>
                    </div>
                  </div>
                </div>
                <div v-if="recurringPayments" class="row">
                  <div class="col-12 m-0">
                    <b-table
                      v-if="repayment.RecurringPayments.length"
                      class="table"
                      hover
                      striped
                      :items="repayment.RecurringPayments"
                      :fields="fieldsRecurringPayments"
                      responsive
                    >
                      <!--DebitViaACH-->
                      <template #cell(DebitViaACH)="row">
                        <b-icon-x-square v-if="!row.item.DebitViaACH" />
                        <b-icon-check-square v-else />
                      </template>
                    </b-table>
                    <div v-else class="flex-center">
                      <p class="text-center p-5">
                       No records found.
                      </p>
                    </div>
                  </div>
                </div>
              </b-tab>
              <!--Payments-->
              <b-tab title="Payments">
                <!--Search Payments-->
                <div class="row">
                  <b-input-group size="sm" class="mb-3 col-12 col-lg-3">
                    <b-form-input
                      id="filter-input"
                      v-model="filterPayments"
                      type="search"
                      placeholder="Enter search text"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        :disabled="!filterPayments"
                        @click="filterPayments = ''"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <div class="col-2 col-lg-3 mb-3">
                    <b-form-group
                      label="Per page"
                      label-for="per-page-select"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="perPagePayments"
                        :options="pageOptionsPayments"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-10 col-lg-1 offset-lg-5 mb-3">
                    <div class="flex-end">
                      <b-icon-arrow-counterclockwise
                        @click="getRepayment"
                        class="btn__refresh"
                        v-b-tooltip.hover
                        title="Refresh"
                      />
                    </div>
                  </div>
                </div>
                <!--Payments Table-->
                <div class="row">
                  <div v-if="repayment.Payments" class="col-12 m-0">
                    <b-table
                      v-if="repayment.Payments.length"
                      class="table"
                      hover
                      striped
                      :items="repayment.Payments"
                      :fields="fieldsPayments"
                      :filter="filterPayments"
                      :filter-included-fields="filterOnPayments"
                      responsive
                      :current-page="currentPagePayments"
                      :per-page="perPagePayments"
                    >
                      <!--DebitViaACH-->
                      <template #cell(DebitViaACH)="row">
                        <b-icon-x-square v-if="!row.item.DebitViaACH" />
                        <b-icon-check-square v-else />
                      </template>
                    </b-table>
                    <div v-else class="flex-center">
                      <p class="text-center p-5">Payments is empty.</p>
                    </div>
                  </div>
                  <!--Payment Table Pagination-->
                  <div class="col-12 flex-center">
                    <b-pagination
                      v-model="currentPagePayments"
                      :total-rows="totalRowsPayments"
                      :per-page="perPagePayments"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </div>
                </div>
              </b-tab>
              <!--Disbursements-->
              <b-tab title="Disbursements">
                <!--Search Distributions-->
                <div class="row">
                  <b-input-group size="sm" class="mb-3 col-12 col-lg-3">
                    <b-form-input
                      id="filter-input"
                      v-model="filterDistributions"
                      type="search"
                      placeholder="Enter search text"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        :disabled="!filterDistributions"
                        @click="filterDistributions = ''"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <div class="col-2 col-lg-3 mb-3">
                    <b-form-group
                      label="Per page"
                      label-for="per-page-select"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="perPageDistributions"
                        :options="pageOptionsDistributions"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-10 col-lg-1 offset-lg-5 mb-3">
                    <div class="flex-end">
                      <b-icon-arrow-counterclockwise
                        @click="getRepayment"
                        class="btn__refresh"
                        v-b-tooltip.hover
                        title="Refresh"
                      />
                    </div>
                  </div>
                </div>
                <!--Distributions Table-->
                <div class="row">
                  <div v-if="repayment.Distributions" class="col-12 m-0">
                    <b-table
                      v-if="repayment.Distributions.length"
                      class="table"
                      hover
                      striped
                      :items="repayment.Distributions"
                      :fields="fieldsDistributions"
                      :filter="filterDistributions"
                      :filter-included-fields="filterOnDistributions"
                      responsive
                      :current-page="currentPageDistributions"
                      :per-page="perPageDistributions"
                    >
                      <!--Not Sent-->
                      <template #cell(NotSent)="row">
                        <b-icon-x-square v-if="!row.item.NotSent" />
                        <b-icon-check-square v-else />
                      </template>
                    </b-table>
                    <div v-else class="flex-center">
                      <p class="text-center p-5">No records found.</p>
                    </div>
                  </div>
                  <!--Distributions Table Pagination-->
                  <div class="col-12 flex-center">
                    <b-pagination
                      v-model="currentPageDistributions"
                      :total-rows="totalRowsDistributions"
                      :per-page="perPageDistributions"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
      <div v-else class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
    <div v-else class="container">
      <div class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import './RepaymentPage.scss'
import RepaymentService from '@/services/repayment/repayment.service'
import Title from '@/components/main-layout/title/Title.vue'
export default {
  name: 'RepaymentPage',
  components: {
    Title,
  },

  metaInfo: {
    title: 'Repayment',
    titleTemplate: '%s | Merchant Portal | Nexi ',
  },
  data() {
    return {
      loading: false,
      title: 'Repayment',
      repayment: {},
      fieldsRecurringPayments: [
        {
          key: 'Status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'PaymentDate',
          label: 'Payment Date',
          sortable: false,
        },

        {
          key: 'DebitViaACH',
          label: 'Debit Via ACH',
          sortable: true,
        },
        {
          key: 'Amount',
          label: 'Amount',
          sortable: true,
        },
        {
          key: 'Notes',
          label: 'Notes',
          sortable: false,
        },
      ],
      fieldsPayments: [
        {
          key: 'Status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'PaymentDate',
          label: 'Payment Date',
          sortable: false,
        },

        {
          key: 'DebitViaACH',
          label: 'Debit Via ACH',
          sortable: true,
        },
        {
          key: 'Amount',
          label: 'Amount',
          sortable: true,
        },
        {
          key: 'Notes',
          label: 'Notes',
          sortable: false,
        },
      ],
      fieldsDistributions: [
        {
          key: 'Week',
          label: 'Week',
          sortable: true,
        },
        {
          key: 'DistributionDate',
          label: 'Date',
          sortable: false,
        },
        {
          key: 'Amount',
          label: 'Amount',
          sortable: true,
        },
        {
          key: 'HoldbackAmount',
          label: 'Hold back Amount',
          sortable: true,
        },
        {
          key: 'AmountSent',
          label: 'Amount Sent',
          sortable: true,
        },
        {
          key: 'DateSent',
          label: 'Date Sent',
          sortable: false,
        },
        {
          key: 'NotSent',
          label: 'Not Sent',
          sortable: true,
        },
        {
          key: 'Notes',
          label: 'Notes',
          sortable: false,
        },
      ],
      balance: true,
      recurringPayments: false,
      filterPayments: null,
      filterOnPayments: [],
      totalRowsPayments: 1,
      currentPagePayments: 1,
      perPagePayments: 20,
      pageOptionsPayments: [20, 40, 60, { value: 1000, text: 'Show all' }],
      filterDistributions: null,
      filterOnDistributions: [],
      totalRowsDistributions: 1,
      currentPageDistributions: 1,
      perPageDistributions: 20,
      pageOptionsDistributions: [20, 40, 60, { value: 1000, text: 'Show all' }],
    }
  },
  computed: {
    auth() {
      return this.$system.user.isAuthenticated
    },
  },
  watch: {
    auth() {
      this.getRepayment()
    },
  },
  created() {
    if (this.$system.user.isAuthenticated) {
      this.getRepayment()
    } else this.$system.firstLoading()
  },
  methods: {
    async getRepayment() {
      try {
        this.loading = true
        const response = await RepaymentService.getRepayment(
          this.$system.user,
          this.$route.params.id,
        )
        if (
          response &&
          response.status == 'success' &&
          response.body &&
          response.body.data
        ) {
          this.repayment = response.body.data
          this.loading = false

          this.totalRowsPayments = this.repayment.Payments.length
          this.totalRowsDistributions = this.repayment.Distributions.length
        } else {
          this.$router.push('/repayments')
          this.loading = false
        }
      } catch (err) {
        this.$router.push('/repayments')
        this.loading = false
      }
    },
    showBalance() {
      this.balance = !this.balance
    },
    showRecurringPayments() {
      this.recurringPayments = !this.recurringPayments
    },
  },
}
</script>
